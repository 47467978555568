<template>
  <div data-layout="site/app" class="bg-forgd-bgd-200 text-forgd-primary-900 min-h-screen font-normal font-display">
    <div class="flex min-h-screen">
      <AppMenuExpandable expanded />
      <div class="flex-grow max-w-forgd mx-auto">
        <slot />
      </div>
    </div>
  </div>

  <UNotifications />
</template>
